import React, { useEffect, useState } from "react";
import Heading from "../../components/SectionHeading/Heading";
import { GrNotes } from "react-icons/gr";
import { IoMdSettings, IoIosAdd } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import WarningIcons from "../../components/WarningIcons/WarningIcons";
import { useDispatch, useSelector } from "react-redux";
import { BiChevronDown, BiChevronRight, BiChevronUp } from "react-icons/bi";
import { Button, Popover, Table } from "antd";
import { useParams } from "react-router";
import AppSettings from "../../components/AppSettings/AppSettings";

import SpinnerLoader from "../../components/SpinnerLoader/SpinnerLoader";
import { setInstalledApps } from "../../reduxStore/actions";
import { stat } from "fs";
import { FaTimesCircle } from "react-icons/fa";
import { colors } from "grommet/themes/base";
import Axios from "axios";
import { apiEndPoints } from "../../constants/ApiEndPoints";

function AlltenantappComponents() {
  // const { edgeId } = useParams();
  const [appSettingsFetched, setAppSettingsFetched] = useState(true);
  const [changeState, setChageState] = useState(0);
  const [isApiLoaded, setIsApiLoaded] = useState(false);
  const [original_AllApplications, setOriginal_AllApplications] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    setIsApiLoaded(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: apiEndPoints.FETCH_ALL_AVAILABLE_APPS,
      headers: {},
    };

    Axios.request(config)
      .then((response) => {
        setIsApiLoaded(false);
        // console.log(JSON.stringify(response.data));
        setOriginal_AllApplications(response.data);
      })
      .catch((error) => {
        setIsApiLoaded(false);
        console.log(error);
      });
  }, []);

  // const _original_AllApplications = [
  //   {
  //     model: "abb.pa.edgenius.application",
  //     type: "abb.pa.edgenius.application.AssetHub.1",
  //     name: "OPC UA Configuration Utility",
  //     version: "1.0.0",
  //     platformType: "connectondemand",
  //     edgescount: "3",
  //     description:
  //       "The OPC UA Configuration Utility provides the capability to connect to an OPC UA Server via the Edgenius Edge node, browse the OPC UA Server and add variables to the edge data stream which can then be subscribed by other applications like ABB Ability Edgenius Dashboard.",
  //   },
  //   {
  //     model: "abb.pa.edgenius.application",
  //     type: "abb.pa.edgenius.application.controlSystem",
  //     name: "ABB Control System Connect",
  //     version: "1.0.0",
  //     platformType: "connectondemand",
  //     description:
  //       "The Control System Connect edge application provides southbound connectivity to the different Distributed Control Systems (DCS) in the 800xA family.",
  //   },
  //   {
  //     model: "abb.pa.edgenius.application",
  //     type: "abb.pa.edgenius.application.modbusconfigurator",
  //     name: "Modbus Configuration Utility",
  //     version: "1.0.0",
  //     platformType: "connectondemand",
  //     description:
  //       "The Modbus Configuration Utility provides the capability to connect to Modbus devices via the Edgenius Edge node and expose the Modbus TCP variables as OPC UA tags.",
  //   },
  //   {
  //     model: "abb.pa.edgenius.application",
  //     type: "abb.pa.edgenius.application.modbusconnect",
  //     name: "Modbus TCP Connect",
  //     version: "1.0.0",
  //     platformType: "connectondemand",
  //     description:
  //       "The Modbus TCP Configuration Utility is divided into two parts. The actual Modbus TCP Connect component running as an Edgenius application and the Modbus TCP Configuration Utility tool.",
  //   },
  //   {
  //     model: "abb.pa.edgenius.application",
  //     type: "abb.pa.edgenius.application.uaconnect",
  //     name: "OPC UA Connect",
  //     version: "1.0.0",
  //     platformType: "connectondemand",
  //     description:
  //       "The Edge OPC UA Connect provides southbound connectivity to access information model and telemetry data from any OPC UA compatible device or system.",
  //   },
  // ];

  useEffect(() => {
    // dispatch(setInstalledApps({ payload: original_AllApplications }));
    resizeend();
  }, []);

  // const original_AllApplications = useSelector(
  //   (state) => state.setInstalledApps
  // );

  const [view, setView] = useState(true);
  const [sudoState, setSudoState] = useState(0);

  const [showAddApplicationPannel, setShowAddApplicationPannel] =
    useState(false);
  const [open, setOpen] = useState(false);
  const [showFullText, setShowFullText] = useState(false);
  const [dymanicnumber, setDynamicNumber] = useState(0);

  const handleArrowClick = () => {
    setShowFullText(!showFullText);
  };

  const original_EdgeApplications = [
    {
      name: "Edge Application ",
      version: "0.3.2",
      ReleaseNotes: "link",
      Licence: "link",
      status: [{ icon: "good", value: "Running" }],
      cpu: [{ icon: "spiral", value: "2" }],
      memory: [{ icon: "spiral", value: "20" }],
      storage: [{ icon: "spiral", value: "5" }],
    },
    {
      name: "Edge Application ",
      version: "0.1.4",
      ReleaseNotes: "link",
      Licence: "link",
      status: [{ icon: "good", value: "Running" }],
      cpu: [{ icon: "spiral", value: "2" }],
      memory: [{ icon: "spiral", value: "20" }],
      storage: [{ icon: "spiral", value: "5" }],
    },
    {
      name: "Edge Application",
      version: "0.2.4",
      ReleaseNotes: "link",
      Licence: "link",
      status: [{ icon: "good", value: "Running" }],
      cpu: [{ icon: "spiral", value: "2" }],
      memory: [{ icon: "spiral", value: "20" }],
      storage: [{ icon: "spiral", value: "5" }],
    },
    {
      name: "Edge Application",
      version: "0.1.4",
      ReleaseNotes: "link",
      Licence: "link",
      status: [{ icon: "good", value: "Running" }],
      cpu: [{ icon: "spiral", value: "2" }],
      memory: [{ icon: "spiral", value: "20" }],
      storage: [{ icon: "spiral", value: "5" }],
    },
    {
      name: "Edge Application",
      version: "0.4.4",
      ReleaseNotes: "link",
      Licence: "link",
      status: [{ icon: "good", value: "Running" }],
      cpu: [{ icon: "spiral", value: "2" }],
      memory: [{ icon: "spiral", value: "20" }],
      storage: [{ icon: "spiral", value: "5" }],
    },
    {
      name: "Edge Application",
      version: "0.9.0.4",
      ReleaseNotes: "link",
      Licence: "link",
      status: [{ icon: "good", value: "Running" }],
      cpu: [{ icon: "spiral", value: "2" }],
      memory: [{ icon: "spiral", value: "20" }],
      storage: [{ icon: "spiral", value: "5" }],
    },
    {
      name: "Edge Application",
      version: "0.9.2.1",
      ReleaseNotes: "link",
      Licence: "link",
      status: [{ icon: "good", value: "Running" }],
      cpu: [{ icon: "spiral", value: "2" }],
      memory: [{ icon: "spiral", value: "20" }],
      storage: [{ icon: "spiral", value: "5" }],
    },
    {
      name: "Edge Application",
      version: "0.11.12.41",
      ReleaseNotes: "link",
      Licence: "link",
      status: [{ icon: "good", value: "Running" }],
      cpu: [{ icon: "spiral", value: "2" }],
      memory: [{ icon: "spiral", value: "20" }],
      storage: [{ icon: "spiral", value: "5" }],
    },
    {
      name: "Edge Application",
      version: "0.11.12.14",
      ReleaseNotes: "link",
      Licence: "link",
      status: [{ icon: "good", value: "Running" }],
      cpu: [{ icon: "spiral", value: "2" }],
      memory: [{ icon: "spiral", value: "20" }],
      storage: [{ icon: "spiral", value: "5" }],
    },
    {
      name: "Edge Application",
      version: "0.1.23.34",
      ReleaseNotes: "link",
      Licence: "link",
      status: [{ icon: "good", value: "Running" }],
      cpu: [{ icon: "spiral", value: "2" }],
      memory: [{ icon: "spiral", value: "20" }],
      storage: [{ icon: "spiral", value: "5" }],
    },
  ];
  const [EdgeApplications, setEdgeApplications] = useState(
    original_EdgeApplications
  );

  // const AvailableApplications = useSelector((state) => state.setAvailableApps);

  // let popoverId = useSelector(state=>state.deploymentProgess);

  // useEffect(()=>{
  // //  let refineId = popoverId.replace(/\s/g, '')
  // // let asdf = popoverId.replace(/\s/g, '')
  // //   console.log(document.getElementById(asdf))
  // },[AvailableApplications])

  // useEffect(()=>{
  //   setShowAddApplicationPannel(false)
  // },[AvailableApplications])

  const handleChange = (value, column) => {
    console.log(value, column);
    if (view) {
      if (value.length == 0) {
        // setAllApplications(original_AllApplications);
      } else {
        // setAllApplications(
        //   original_AllApplications.filter((val) => val[column].includes(value))
        // );
      }
    } else {
      if (value.length == 0) {
        setEdgeApplications(original_EdgeApplications);
      } else {
        setEdgeApplications(
          original_EdgeApplications.filter((val) => val[column].includes(value))
        );
      }
    }
  };

  let rtime;
  let timeout = false;
  let delta = 200;

  window.addEventListener("resize", (event) => {
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;

      let ele = document.getElementById("ViewContainer");

      let num = ele.offsetWidth;

      num = Math.round(num / 20);

      setDynamicNumber(num);
      // console.log(num)
    }
  }
  const tableStat = (stat) => {
    return stat.map((d, i) => (
      <div
        key={i}
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "15px",
          alignItems: "center",
        }}
      >
        <span style={{ fontWeight: "500", minWidth: "45px" }}>{d.value}%</span>
        <WarningIcons type={d.icon} value={d.value} />
      </div>
    ));
  };

  const toggleShowHide = (id) => {
    document.getElementById(`overFlow_${id}`).classList.toggle("overFlowText");
    document.getElementById(`button_${id}`).classList.toggle("rotate");
  };

  const columns = [
    {
      title: "Application Name",
      dataIndex: "name",
      width: "20%",
      ellipsis: true,
      render: (text, record) => {
        // if(record?.children?.length>0){
        //   return <a onClick={()=>{
        //     const href=`/appDetails?edgeId=${edgeId}&settingData=${JSON.stringify(record.originalObject)}&isAppInstalled=true`

        //       window.location.href = `${href}`
        //   }}>{text}</a>
        // }else{
        return <span>{text}</span>;
        // }
      },
      sorter: (a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      },
      // responsive: ['md'],
    },
    {
      title: "Version",
      dataIndex: "version",
      width: "20%",
      ellipsis: true,
      // render: (version) => (
      //   version!=null?<span> {version}</span>:'-'

      // ),
      // responsive: ['md'],
    },
    {
      title: "Description",
      dataIndex: "description",
      // ellipsis: true,
      width: "60%",
      render: (description, record, index) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <span className="overFlowText" id={`overFlow_${index}`}>
              {description == undefined
                ? "No description at the moment."
                : `${description}`}
              {setTimeout(() => {
                console.log(document.getElementById(`overFlow_0`).offsetWidth);
              }, 5000)}
              {/* {showFullText
              ? description 
              : description.slice(0, dymanicnumber).concat("...")} */}
            </span>
            {/* {description==undefined?'':( */}
            <span style={{ cursor: "pointer" }}>
              <BiChevronRight
                fontSize={20}
                style={{ cursor: "pointer" }}
                className=""
                id={`button_${index}`}
                onClick={() => toggleShowHide(index)}
              />
            </span>
            {/* )} */}
          </div>
        );
      },
      // responsive: ['md'],
    },
  ];

  const GenerateTable = (applications) => {
    return (
      <Table
        columns={columns}
        dataSource={applications}
        pagination={false}
        // scroll={{
        //   y: customHeight - 45,
        // }}
        size="small"
        // expandable={{
        //   expandIcon: ({ expanded, onExpand, record }) => {
        //     if (record?.children?.length > 0) {
        //       return expanded ? (
        //         <BiChevronDown
        //           style={{
        //             fontSize: "20px",
        //             marginBottom: "-5px",
        //             width: "30px",
        //             marginLeft: "-10px",
        //           }}
        //           onClick={(e) => {
        //             onExpand(record, e);
        //             e.stopPropagation();
        //           }}
        //         />
        //       ) : (
        //         <BiChevronRight
        //           style={{
        //             fontSize: "20px",
        //             marginBottom: "-5px",
        //             width: "30px",
        //             marginLeft: "-10px",
        //           }}
        //           onClick={(e) => {
        //             onExpand(record, e);
        //             e.stopPropagation();
        //           }}
        //         />
        //       );
        //     } else {
        //       return (
        //         <BiChevronRight
        //           style={{
        //             fontSize: "0px",
        //             marginBottom: "-5px",
        //             width: "30px",
        //             marginLeft: "-10px",
        //           }}
        //           onClick={(e) => {
        //             onExpand(record, e);
        //             e.stopPropagation();
        //           }}
        //         />
        //       );
        //     }
        //   },
        // }}
        style={{
          width: "100%",
          flex: "1",
        }}
      />
    );
  };

  const render_AddAppBtn = () => {
    return (
      <>
        {/* <span
        onClick={() => setShowAddApplicationPannel(!showAddApplicationPannel)}
        style={{
          marginLeft: "auto",
          background: "#ececec",
          display: "flex",
          alignItems: "center",
          borderRadius: "31px",
          padding: "5px 10px",
          boxShadow: " 0px 4px 6px -1px #d2d2d2",
          cursor: "pointer",
        }}
      >
        <IoIosAdd style={{ fontSize: "30px" }} /> Add Application
      </span> */}

        <span
          style={{
            marginLeft: "auto",
            background: "grey",
            color: "white",
            display: "flex",
            alignItems: "center",
            borderRadius: "31px",
            padding: "5px 10px",
            boxShadow: " 0px 4px 6px -1px #d2d2d2",
            cursor: "pointer",
          }}
        >
          <IoIosAdd style={{ fontSize: "30px" }} /> OnBoard Application
        </span>
      </>
    );
  };

  return (
    <div className="App">
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "30px" }}
      >
        <style
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "10px",
          }}
        >
          <Heading text="Applications" />
          {isApiLoaded && <SpinnerLoader />}
        </style>
        {
          // render_AddAppBtn()
        }
      </div>
      <div className="tableBox">
        {/* <div className="screenTabs">
        <span
          className={view == true ? "viewOptions active" : "viewOptions"}
          onClick={() => setView(true)}
        >
          All
        </span>

        <span
          className={view == false ? "viewOptions active" : "viewOptions"}
          onClick={() => {
            setView(false);
          }}
        >
          Edge
        </span>
      </div> */}

        <div
          className="ViewContainer"
          id="ViewContainer"
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: "5px",
            height: "calc(90vh - 17vh)",
          }}
        >
          <div style={{ flex: "1", backgroundColor: "#fff", height: "auto" }}>
            {/* {view
              ? GenerateTable(original_AllApplications)
              : GenerateTable(EdgeApplications)} */}
            {!isApiLoaded && original_AllApplications.length == 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <div style={{ margin: "100px" }}>
                  <span
                    style={{
                      fontWeight: "600",
                      fontSize: "22px",
                    }}
                  >
                    Welcome to Edgenius!,{" "}
                  </span>
                  <br />
                  <span>
                    Currently, there are no applications available in the
                    tenant. Kindly reach out to the system administrator for
                    application onboarding assistance. Follow more details on
                    IIOT Information Portal.{" "}
                  </span>
                  <br />
                  <br />
                  {/* 
                  <span
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      columnGap: "5px",
                    }}
                  >
                    Click {render_AddAppBtn()} to embark on your journey to a
                    powerful and personalized edge platform.{" "}
                  </span>
                  <br />
                  <span
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      // columnGap: "1px",
                    }}
                  >
                    Browse through the list of all avaiable applications and use
                    "<IoIosAdd style={{ fontSize: "30px" }} />" icon to add your
                    favorite application to the Edge.
                  </span> */}
                </div>
              </div>
            )}

            {original_AllApplications.length > 0 &&
              GenerateTable(original_AllApplications)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlltenantappComponents;
