import React, { useEffect, useState } from "react";
import Heading from "../../components/SectionHeading/Heading";
import { GrNotes } from "react-icons/gr";
import { IoMdSettings, IoIosAdd, IoIosRemove } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import WarningIcons from "../../components/WarningIcons/WarningIcons";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, WithTooltip } from "@abb/abb-common-ux-react";
import { Button, Popover, Table, Modal, Form, Input, message } from "antd";
import { useParams } from "react-router";
import AppSettings from "../../components/AppSettings/AppSettings";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import SpinnerLoader from "../../components/SpinnerLoader/SpinnerLoader";
import {
  setInstalledApps,
  setAvailableApps,
  setPageNotifications,
  setisAllDependenciesInstalled,
} from "../../reduxStore/actions";
import { stat } from "fs";
import { FaTimesCircle } from "react-icons/fa";
import { availableTypes } from "../../Utils/Constants";
import { routes } from "../../constants/RoutesConstants";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { IoSyncSharp } from "react-icons/io5";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";
import Axios from "axios";
import {
  CreateGuid,
  generateTime,
  renderCalculatedTime,
} from "../../constants/commonFunction";
import { GeneralConstants } from "../../constants/GeneralConstants";
import { FormField } from "grommet";

function AllapplicationsComponents({ apiLoaded }) {
  // const isSummaryVisible = useSelector(state=>state.setSummaryVisible)
  let detailsData = useSelector((state) => state.setEdgeDetails);
  const isSummaryVisible = false;
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");
  let oldNotifications = useSelector((state) => state.setPageNotifications);
  const [appSettingsFetched, setAppSettingsFetched] = useState(true);
  const [changeState, setChageState] = useState(0);

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [applicationToDelete, setApplicationToDelete] = useState(null);
  const [applicationToUpdate, setApplicationToUpdate] = useState(null);
  const dispatch = useDispatch();
  const [view, setView] = useState(0);
  const [customHeight, setCustomHeight] = useState(0);
  const [load, setLoad] = useState(false);
  const [updateload, setUpdateLoad] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [isDeleteButtonEnabled, setIsDeleteButtonEnabled] = useState(false);
  const _AvailableApplications = useSelector((state) => state.setAvailableApps);
  const [unistallmodalLoad, setUninstallModalLoad] = useState(false);
  const original_AllApplications = useSelector(
    (state) => state.setInstalledApps
  );
  const [upstreamData, setUpStreamdata] = useState({});

  let [deleteAppForm] = Form.useForm();

  const showDeleteModal = (type, edgeId, name) => {
    setUninstallModalLoad(true);
    setDeleteModalVisible(true);
    let fetchAppUpstream = apiEndPoints.FETCH_APP_UPSTREAM_DEPENDENCIES.replace(
      "*edgeId*",
      edgeId
    );
    fetchAppUpstream = fetchAppUpstream.replace("*typeId*", type);

    let Appupstream = {
      method: "get",
      maxBodyLength: Infinity,
      url: fetchAppUpstream,
      headers: {
        // Authorization: "••••••",
      },
    };

    setApplicationToDelete({ type: type, edgeId: edgeId, name: name });
    Axios.request(Appupstream)
      .then((response) => {
        // setUninstallLoad(false);
        setUpStreamdata(response.data);
        deleteAppForm.resetFields();
        setIsDeleteButtonEnabled(false);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setUninstallModalLoad(false); // Set loading to false when API request completes
      });
  };

  const showUpdateModal = (record) => {
    setApplicationToUpdate(record);
    setUpdateModalVisible(true);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
  };

  const handleUpdateCancel = () => {
    setUpdateModalVisible(false);
  };

  const handleDeleteApplication = () => {
    handleDeleteOk(applicationToDelete);
  };

  const handleUpdateOk = () => {
    handleUpdateApplication(applicationToUpdate);
  };

  let notiMsg;
  const handleDeleteOk = async ({ type, edgeId }) => {
    const uninstalledApp = original_AllApplications.find(
      (app) => app.type === type
    );
    if (confirmationText.toLowerCase() === "yes") {
      try {
        setLoad(true);
        const response = await Axios.delete(
          apiEndPoints.DELETE_APPLICATION(edgeId, type)
        );

        if (response.status === 200) {
          // const uninstalledApp = original_AllApplications.find(
          //   (app) => app.type === type
          // );

          const updatedInstalledApps = original_AllApplications.filter(
            (app) => app.type !== type
          );
          dispatch(setInstalledApps({ payload: updatedInstalledApps }));

          // const updatedAvailableApps = _AvailableApplications.concat([
          //   uninstalledApp,
          // ]);
          // dispatch(setAvailableApps({ payload: updatedAvailableApps }));
          setLoad(false);

          AddEdgeSettingsNotification(
            `Application uninstalled successfully`,
            `AppRelatedUpdate_${CreateGuid()}`,

            {
              edgeId: edgeId,
              isEdge: false,
              modalKey: `AppRelatedUpdate_${CreateGuid()}`,
              setting_type: type,
              settingSectionName: uninstalledApp.displayName,
              operation_type: "delete",
              edgeName: localStorage.getItem("edgeName"),
              appName: uninstalledApp.displayName,
              successMessage: `Application uninstalled successfully`,
              time: generateTime(),
              isComplete: false,
              limit: 3,
              currStateValue: 0,
              icon: "good",
            }
          );
          setDeleteModalVisible(false);
          refreshInstalledApps();
          refreshAvailableApps();
        } else {
          setLoad(false);
          notiMsg = "Application uninstall failed, please try again";
          message.error(notiMsg);

          AddEdgeSettingsNotification(
            `Application uninstall failed, please try again`,
            `AppRelatedUpdate_${CreateGuid()}`,

            {
              edgeId: edgeId,
              isEdge: false,
              modalKey: `AppRelatedUpdate_${CreateGuid()}`,
              edgeName: localStorage.getItem("edgeName"),
              appName: uninstalledApp.displayName,
              successMessage: `Application uninstall failed, please try again`,
              time: generateTime(),
              isComplete: true,
              limit: 3,
              currStateValue: 0,
              icon: "failed",
            }
          );

          setDeleteModalVisible(false);
        }
      } catch (error) {
        console.error(error);
        setLoad(false);
        notiMsg = "Application uninstall failed, please try again";
        message.error(notiMsg);
        AddEdgeSettingsNotification(
          `Application uninstall failed, please try again`,
          `AppRelatedUpdate_${CreateGuid()}`,

          {
            edgeId: edgeId,
            isEdge: false,
            modalKey: `AppRelatedUpdate_${CreateGuid()}`,
            edgeName: localStorage.getItem("edgeName"),
            appName: uninstalledApp.displayName,
            successMessage: `Application uninstall failed, please try again`,
            time: generateTime(),
            isComplete: true,
            limit: 3,
            currStateValue: 0,
            icon: "failed",
          }
        );

        setConfirmationText("");
        setIsDeleteButtonEnabled(false);

        setDeleteModalVisible(false);
      }
    } else {
      message.error("Please type 'yes' to confirm deletion.");
    }
  };

  const [isAppUpdateAvailable, setIsAppUpdateAvailable] = useState({});

  useEffect(() => {
    let checkAppUpdate = apiEndPoints.CHECK_UPDATE_APPLICATION.replace(
      "*edgeId*",
      edgeId
    );
    checkAppUpdate = checkAppUpdate.replace("*typeId*", "All");
    const fetchUpdateStatus = async () => {
      try {
        const response = await Axios.get(checkAppUpdate);
        const updateStatusMap = {};
        response.data.forEach((app) => {
          updateStatusMap[app.applicationTypeId] =
            app.applicationUpdateAvailable;
        });
        setIsAppUpdateAvailable(updateStatusMap);
      } catch (error) {
        console.error("Error fetching update status:", error);
      }
    };
    fetchUpdateStatus();
  }, []);
  let AppUpdate = apiEndPoints.UPDATE_APPLICATION.replace("*edgeId*", edgeId);
  const AddEdgeSettingsNotification = async (notiText, notiId, data) => {
    let previosData = [];
    if (oldNotifications.newData.length > 0) {
      previosData = [...oldNotifications.newData];
    }

    previosData.push({
      notiId: `${notiId}`,
      text: `${notiText}`,
      time: data.time,
      data,
    });

    dispatch(
      setPageNotifications({
        payload: {
          isNew: true,
          data: oldNotifications.data,
          newData: previosData,
          // ...oldNotifications.newData,
        },
      })
    );
  };

  const handleUpdateApplication = async ({ type, name }) => {
    setUpdateLoad(true);

    AppUpdate = AppUpdate.replace("*type*", type);
    try {
      const response = await Axios.post(AppUpdate);
      if (response.status === 200) {
        setIsAppUpdateAvailable((prevState) => ({
          ...prevState,
          [type]: false,
        }));
        setUpdateLoad(false);

        AddEdgeSettingsNotification(
          `Application updated successfully`,
          `AppRelatedUpdate_${CreateGuid()}`,

          {
            edgeId: edgeId,
            isEdge: false,
            modalKey: `AppRelatedUpdate_${CreateGuid()}`,
            setting_type: type,
            settingSectionName: name,
            operation_type: "configure",
            edgeName: localStorage.getItem("edgeName"),
            appName: name,
            successMessage: `Application updated successfully`,
            time: generateTime(),
            isComplete: false,
            limit: 3,
            currStateValue: 0,
            icon: "good",
          }
        );

        setUpdateModalVisible(false);
        refreshInstalledApps();
      } else {
        setUpdateLoad(false);
        notiMsg = "Application update failed, please try again";
        message.error(notiMsg);

        AddEdgeSettingsNotification(
          `Application update failed, please try again`,
          `AppRelatedUpdate_${CreateGuid()}`,

          {
            edgeId: edgeId,
            isEdge: false,
            modalKey: `AppRelatedUpdate_${CreateGuid()}`,
            edgeName: localStorage.getItem("edgeName"),
            appName: name,
            successMessage: `Application update failed, please try again`,
            time: generateTime(),
            isComplete: true,
            limit: 3,
            currStateValue: 0,
            icon: "failed",
          }
        );
        setUpdateModalVisible(false);
      }
    } catch (error) {
      console.error(error);
      setUpdateLoad(false);
      notiMsg = "Application update initialization failed, please try again";
      message.error(notiMsg);
      AddEdgeSettingsNotification(
        `${notiMsg}`,
        `AppRelatedUpdate_${CreateGuid()}`,

        {
          edgeId: edgeId,
          isEdge: false,
          modalKey: `AppRelatedUpdate_${CreateGuid()}`,
          edgeName: localStorage.getItem("edgeName"),
          appName: name,
          successMessage: `${notiMsg}`,
          time: generateTime(),
          isComplete: true,
          limit: 3,
          currStateValue: 0,
          icon: "failed",
        }
      );

      setUpdateModalVisible(false);
    }
  };

  const refreshInstalledApps = async () => {
    try {
      const updatedInstalledApps = await fetchUpdatedInstalledApps();
      // const updatedInstalledApps = await original_AllApplications;
      dispatch(setInstalledApps({ payload: updatedInstalledApps }));
      handleFetchUpdateStatus();
    } catch (error) {
      console.error("Error refreshing installed apps:", error);
    }
  };
  const refreshAvailableApps = async () => {
    try {
      const updatedAvailableApps = await fetchUpdatedAvailableApps();
      // const updatedInstalledApps = await original_AllApplications;
      dispatch(setAvailableApps({ payload: updatedAvailableApps }));
    } catch (error) {
      console.error("Error refreshing available apps:", error);
    }
  };

  const fetchUpdatedInstalledApps = async () => {
    let toGetInstalledApps = apiEndPoints.FETCH_INSTALLED_APPS.replace(
      "*edgeId*",
      edgeId
    );
    const response = await Axios.get(toGetInstalledApps);
    // const response = await original_AllApplications;
    return response.data;
  };

  const fetchUpdatedAvailableApps = async () => {
    let toGetAvailableApps = apiEndPoints.FETCH_AVAILABLE_APPS.replace(
      "*edgeId*",
      edgeId
    );
    const response = await Axios.get(toGetAvailableApps);
    return response.data;
  };

  const handleFetchUpdateStatus = async () => {
    try {
      // Call the API to fetch update status
      let checkAppUpdate = apiEndPoints.CHECK_UPDATE_APPLICATION.replace(
        "*edgeId*",
        edgeId
      );
      checkAppUpdate = checkAppUpdate.replace("*typeId*", "All");
      const response = await Axios.get(checkAppUpdate);

      // Update the application update status in the state
      const updateStatusMap = {};
      response.data.forEach((app) => {
        updateStatusMap[app.applicationTypeId] = app.applicationUpdateAvailable;
      });
      setIsAppUpdateAvailable(updateStatusMap);
    } catch (error) {
      console.error("Error fetching update status:", error);
    }
  };

  // useEffect(() => {
  //   refreshInstalledApps();
  // }, []);

  const [refactoredInstalledApp, setRefactoredInstalledApp] = useState([]);

  const [sudoState, setSudoState] = useState(0);

  const [showAddApplicationPannel, setShowAddApplicationPannel] =
    useState(true);

  let initialPopupState = [];
  // AvailableApplications.map((app)=>{

  //   initialPopupState.push(false)
  // })

  const [open, setOpen] = useState({});

  const hide = () => {
    closeAllPopup();
  };
  const handleOpenChange = (index) => {
    // initialPopupState[index] = !initialPopupState[index]
  };

  const [selectedOpen, setSelectedOpen] = useState(0);

  useEffect(() => {
    closeAllPopup();
  }, [_AvailableApplications]);

  const closeAllPopup = () => {
    let openedModal = {};

    for (let val = 0; val < _AvailableApplications.length; val++) {
      openedModal[val] = false;
    }

    setOpen(openedModal);
  };

  const setThisOpen = (index) => {
    for (let val = 0; val < _AvailableApplications.length; val++) {
      if (index == val) {
        open[val] = true;
      } else {
        open[val] = false;
      }
    }
  };

  const tableStat = (stat) => {
    return stat.map((d, i) => (
      <div
        key={i}
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "15px",
          alignItems: "center",
        }}
      >
        <span style={{ fontWeight: "500", minWidth: "45px" }}>{d.value}%</span>
        <WarningIcons type={d.icon} value={d.value} />
      </div>
    ));
  };

  const showHideModules = (id) => {
    let concatId = `canbehidden_${id}`;
    let parentRowId = `parentRow_${id}`;
    document.getElementById(`add_${id}`).classList.toggle("displaynone");
    document.getElementById(`remove_${id}`).classList.toggle("displaynone");
    let allparentTd = document
      .getElementById(parentRowId)
      .querySelectorAll("td");
    for (let i = 0; i < allparentTd.length; i++) {
      allparentTd[i].classList.toggle("hiddenParentUnderline");
    }
    document.getElementById(concatId).classList.toggle("hiddenModules");
  };
  useEffect(() => {
    if (original_AllApplications.length > refactoredInstalledApp.length) {
      setView((prev) => prev + 1);
    }
    // console.log("riunned");
  });

  useEffect(() => {
    if (original_AllApplications.length == 0) {
      return;
    }

    let correctedInstalledApps = [];

    original_AllApplications.map((app, key) => {
      // console.log(app);
      if (app?.applicationModuleMetricsList?.length > 0) {
        let modulesList = [];
        app?.applicationModuleMetricsList?.map((module, moduleKey) => {
          // if(module.PodStatsList.length>0){
          modulesList.push({
            key: `${module?.displayName}_${moduleKey}_${app.model}_${key}`,
            name: `${
              module?.displayName == null ||
              module?.displayName == "" ||
              module?.displayName == undefined
                ? module?.name
                : module?.displayName
            }`,
            cpu: `${module?.cpu}`,
            memory: `${module?.memory}`,
            status: `${module?.status}`,
            version: module?.installedVersion,
          });
          // }
        });
        correctedInstalledApps.push({
          originalObject: app,
          key: `${app.model}_app_${key}`,
          name: app?.displayName,
          type: app?.type,
          model: app?.model,
          status: `${app?.status == "Error" ? "Pending" : app?.status}`,
          version: app?.installedVersion,
          new_version: app?.version,
          cpu: app?.cpu,
          memory: app?.memory,
          isClickable: true,
          children: modulesList,
        });
      } else {
        correctedInstalledApps.push({
          originalObject: app,
          key: `${app.model}_app_${key}`,
          name: app?.displayName,
          type: app?.type,
          model: app?.model,
          status: `${app?.status == "Error" ? "Pending" : app?.status}`,
          version: app?.installedVersion,
          new_version: app?.version,
          cpu: app?.cpu,
          memory: app?.memory,
          isClickable: true,
        });
      }
      // console.log("Corrected InstalledAPs", correctedInstalledApps);
      setRefactoredInstalledApp(correctedInstalledApps);
    });
  }, [original_AllApplications, view]);

  const columns = [
    {
      title: "Installed Application",
      dataIndex: "name",
      width: "30%",
      ellipsis: true,
      // render: (name) => (
      //   <Tooltip placement="topLeft" title={name}>
      //     {name}
      //   </Tooltip>
      // ),
      render: (text, record) => {
        if (record?.isClickable) {
          return (
            <a
              // onClick={() => {
              //   const href = `/appDetails?edgeId=${edgeId}&settingData=${JSON.stringify(
              //     record.originalObject
              //   )}&isAppInstalled=true`;

              //   window.location.href = `${href}`;
              // }}
              href={`/appDetails?edgeId=${edgeId}&settingData=${JSON.stringify(
                record.originalObject
              )}&isAppInstalled=true`}
            >
              {text}
            </a>
          );
        } else {
          return <span>{text}</span>;
        }
      },
      sorter: (a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      },
      // responsive: ['md'],
    },
    {
      title: "Version",
      dataIndex: "version",
      width: "10%",
      ellipsis: true,
      render: (version) => (version != null ? <span> {version}</span> : "-"),
      // responsive: ['md'],
    },
    {
      title: "Status",
      dataIndex: "status",
      ellipsis: true,
      width: "15%",

      render: (status) => (status == undefined ? "-" : <span>{status}</span>),
      // responsive: ['md'],
    },
    {
      title: "CPU",
      dataIndex: "cpu",
      ellipsis: true,
      render: (cpu) =>
        cpu != undefined
          ? tableStat([
              {
                icon: "spiral",
                // value: Math.round(cpu),
                value: cpu,
              },
            ])
          : "-",
      // responsive: ['md'],
      width: "15%",
    },
    {
      title: "Memory",
      dataIndex: "memory",
      ellipsis: true,
      render: (memory) =>
        memory != undefined
          ? tableStat([
              {
                icon: "spiral",
                // value: Math.round(memory),
                value: memory,
              },
            ])
          : "-",
      width: "15%",
      // responsive: ['md'],
    },
    {
      title: "Actions",
      dataIndex: "actions",
      ellipsis: true,
      width: "15%",
      render: (_, record, i) => {
        const showUpdateIcon = isAppUpdateAvailable[record.type];
        const showDeleteIcon = record.type !== undefined;
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {showDeleteIcon && (
              <Tooltip title="Uninstall Application">
                <RiDeleteBin6Fill
                  style={{
                    color: "#525252",
                    cursor: "pointer",
                    marginRight: "20px",
                  }}
                  onClick={() =>
                    showDeleteModal(record.type, edgeId, record.name)
                  }
                />
              </Tooltip>
            )}
            {showUpdateIcon && (
              <WithTooltip>
                <IoSyncSharp
                  style={{
                    color: "#1f1f1f",
                    cursor: "pointer",
                  }}
                  onClick={() => showUpdateModal(record)}
                />
                <Tooltip>
                  <p>Update Available</p>
                  <p>Latest Version {record?.new_version}</p>
                </Tooltip>
              </WithTooltip>
            )}
            {/* {record.isClickable &&
              !showUpdateIcon && <i style={{marginLeft:'40px'}}>Updated {renderCalculatedTime(1715836604168)}</i>
            } */}
          </div>
        );
      },
    },
  ];

  const GenerateTable = (applications) => {
    return (
      <Table
        columns={columns}
        dataSource={applications}
        pagination={false}
        scroll={{
          y: customHeight - 45,
        }}
        size="small"
        expandable={{
          // expandedRowRender: record => (<p>{record.children[0].name}</p>),
          expandIcon: ({ expanded, onExpand, record }) => {
            if (record?.children?.length > 0) {
              return expanded ? (
                <BiChevronDown
                  style={{
                    fontSize: "20px",
                    marginBottom: "-5px",
                    width: "30px",
                    marginLeft: "-10px",
                  }}
                  onClick={(e) => {
                    onExpand(record, e);
                    e.stopPropagation();
                  }}
                />
              ) : (
                <BiChevronRight
                  style={{
                    fontSize: "20px",
                    marginBottom: "-5px",
                    width: "30px",
                    marginLeft: "-10px",
                  }}
                  onClick={(e) => {
                    onExpand(record, e);
                    e.stopPropagation();
                  }}
                />
              );
            } else {
              return (
                <BiChevronRight
                  style={{
                    fontSize: "0px",
                    marginBottom: "-5px",
                    width: "30px",
                    marginLeft: "-10px",
                  }}
                  onClick={(e) => {
                    onExpand(record, e);
                    e.stopPropagation();
                  }}
                />
              );
            }
          },
        }}
        style={
          {
            // width: "70%",
            // flex: "1",
            // overflow:'auto'
          }
        }
      />
    );
  };

  const render_AddAppBtn = () => {
    return (
      <span
        onClick={() => setShowAddApplicationPannel(!showAddApplicationPannel)}
        style={{
          marginLeft: "auto",
          background: "#d1ebff",
          display: "inline-flex",
          alignItems: "center",
          borderRadius: "31px",
          padding: "5px 10px",
          // boxShadow: " 0px 4px 6px -1px #d2d2d2",
          cursor: "pointer",
          width: "215px",
        }}
      >
        <IoIosAdd style={{ fontSize: "24px" }} />{" "}
        <span>View Available Applications</span>
      </span>
    );
  };

  const adjustHeight = () => {
    let getMainContainer = document.getElementsByClassName("gridContainer");
    // let getTheAboveApp = getMainContainer[0].getElementsByClassName('App');
    // let appHeight = getTheAboveApp[0].clientHeight;

    let finalHeight = getMainContainer[0].clientHeight - 100;
    // console.log("value of inner height",getMainContainer)
    setCustomHeight(finalHeight);
  };

  let rtime;
  let timeout = false;
  let delta = 100;

  window.addEventListener("resize", (event) => {
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;
      adjustHeight();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      resizeend();
    }, 200);
  }, []);

  const checkApplicationSettings = (settingData) => {
    let fetchApplicationSettings;
    fetchApplicationSettings =
      apiEndPoints.FETCH_AVAILABLE_APPLICATION_SETTINGS;

    fetchApplicationSettings = fetchApplicationSettings.replace(
      "*typeId*",
      settingData.type
    );

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: fetchApplicationSettings,
      headers: {
        // "Content-Type": "application/json",
      },
    };

    Axios.request(config)
      .then((response) => {
        console.log("App settings", response.data);
        // setIsApiLoaded(true);
        // if (response.data.length == 0) {
        //   setIsDataPresent(false);
        //   return;
        // } else setIsDataPresent(true);
        // setAllSettings(response.data);
        // response.data.map((sys, key) => {
        //   if (sys.sectionName == "System Configuration") {
        //     setSystemSettings(response?.data?.[key]);
        //   }
        // });
        // setApplicationObjectId(response?.data?.[0]?.applicationObjectId);
      })
      .catch((error) => {
        console.log(error);
      });

    return (
      <div style={{ padding: "10px" }}>
        <SpinnerLoader />
      </div>
    );
  };

  const [showSettings, setShowSettings] = useState(false);
  const [conId, setConId] = useState("");
  const showAppSettings = (containerId) => {
    dispatch(setisAllDependenciesInstalled({ payload: null }));
    let container = document?.getElementById(containerId);
    setShowSettings(false);
    setConId("");
    if (container?.style?.visibility == "visible") {
      if (container !== null) {
        setShowSettings(false);
        container.style.visibility = "hidden";
      }
    } else {
      if (container !== null) {
        container.style.visibility = "visible";
        setConId(containerId);
        setShowSettings(true);
      }
    }
  };

  return (
    <div className="App">
      <Modal
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            Warning: Uninstall -
            {applicationToDelete?.name == undefined
              ? ""
              : applicationToDelete?.name}
            {unistallmodalLoad && (
              <SpinnerLoader style={{ marginLeft: "10px" }} />
            )}
          </div>
        }
        open={deleteModalVisible}
        // onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      >
        {!unistallmodalLoad && upstreamData?.isAnyUpStreamApplicationExist ? (
          <div
            style={{
              fontSize: "medium",
              marginTop: "20px",
              fontFamily: "ABBVoice",
            }}
          >
            <span style={{ fontFamily: "ABBVoice", fontSize: "15px" }}>
              {`Please Note: Uninstalling ${applicationToDelete?.name} will impact the following dependent applications on the Edge.`}
            </span>
            <div>
              {Object.values(upstreamData?.upStreamDependencies).map(
                (dependency, index) => (
                  <div
                    className="text_1"
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <span>Dependent Applications:</span>
                    <span>{dependency}</span>
                  </div>
                )
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        <p style={{ fontSize: "medium", fontFamily: "ABBVoice" }}>
          This is an irreversible action. To proceed with the uninstallation,
          please type 'Yes'. To cancel, click 'Cancel'.
        </p>
        <Form
          form={deleteAppForm}
          onFinish={handleDeleteApplication}
          layout="vertical"
        >
          <Form.Item
            // label="Type 'yes' to confirm:"
            name="confirmation"
            rules={[
              // {
              //   required: true,
              //   message: "Please type 'yes' to confirm.",
              // },
              {
                validator: (_, value) => {
                  if (value.toLowerCase() === "yes") {
                    setIsDeleteButtonEnabled(true);
                    return Promise.resolve();
                  }
                  setIsDeleteButtonEnabled(false);
                  return Promise.reject("Please type 'yes' to confirm.");
                },
              },
            ]}
          >
            <Input
              autocomplete="off"
              onChange={(e) => {
                setConfirmationText(e.target.value);
              }}
            />
          </Form.Item>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: "10px",
            }}
          >
            <Button
              style={{
                background: isDeleteButtonEnabled ? "#1f1f1f" : "#848484",
                display: "flex",
                alignItems: "center",
                borderRadius: "30px",
                // padding: "2px 10px",
                cursor: "pointer",
                color: "white",
                fontFamily: "ABBVoice Regular",
              }}
              htmlType="submit"
              // onClick={handleDeleteOk}
              disabled={!isDeleteButtonEnabled}
              loading={load}
            >
              Confirm Uninstall
            </Button>
            <Button
              style={{
                borderRadius: "30px",
                // marginTop: "20px",
                // marginLeft: "20px",
                fontWeight: "500",
                fontFamily: "ABBVoice Regular",
              }}
              onClick={handleDeleteCancel}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        title="Update Application?"
        open={updateModalVisible}
        // onOk={handleDeleteOk}
        onCancel={handleUpdateCancel}
      >
        <p style={{ fontSize: "medium" }}>
          Please confirm to update the application.
        </p>
        <Form
          onFinish={handleUpdateApplication}
          layout="vertical"
          style={{ display: "flex", gap: "10px", justifyContent: "end" }}
        >
          <Button
            style={{
              background: "#1f1f1f",
              display: "flex",
              alignItems: "center",
              borderRadius: "30px",
              // padding: "2px 10px",
              cursor: "pointer",
              color: "white",
              fontFamily: "ABBVoice Regular",
            }}
            type="primary"
            onClick={handleUpdateOk}
            loading={updateload}
          >
            Update
          </Button>
          <Button
            style={{
              borderRadius: "30px",
              // marginTop: "20px",
              // marginLeft: "20px",
              fontWeight: "500",
              fontFamily: "ABBVoice Regular",
            }}
            onClick={handleUpdateCancel}
          >
            Cancel
          </Button>
        </Form>
      </Modal>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "30px" }}
      >
        <style
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "10px",
          }}
        >
          <Heading text="Applications" />
          {!apiLoaded && <SpinnerLoader />}
        </style>
        {
          // render_AddAppBtn()
        }
      </div>
      <div className="tableBox">
        <div
          className="ViewContainer"
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: "5px",
            // height: `${
            //   isSummaryVisible ? "calc(52vh - 12vh)" : "calc(80vh - 12vh)"
            // }`,
            height: `${customHeight}px`,
            width: "100%",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              // diplay: "contents",
              width: "70%",
              backgroundColor: "#fff",
              height: "auto",
            }}
          >
            {/* {view
              ? GenerateTable(original_AllApplications)
              : GenerateTable(EdgeApplications)} */}
            {apiLoaded && original_AllApplications.length == 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  flex: 1,
                }}
              >
                <div style={{ margin: "100px 0px" }}>
                  <span
                    style={{
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    Welcome to Edgenius,{" "}
                  </span>
                  <br />
                  <br />
                  <span style={{ fontSize: "15px" }}>
                    It's a bit lonely out here on the edge without any
                    applications installed.{" "}
                  </span>
                  <br />
                  {/* <br /> */}

                  {/* <span
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      columnGap: "5px",
                    }}
                  > */}
                  {/* <span>Click</span> {render_AddAppBtn()} <span>to embark on your journey to a
                    powerful and personalized edge platform.</span> */}
                  {/* </span> */}
                  {/* <br /> */}
                  <span
                    style={{
                      fontSize: "15px",
                      display: "flex",
                      gap: "5px",
                    }}
                  >
                    <span>
                      {" "}
                      Browse through the list of all available applications and
                      use
                    </span>
                    <span
                      style={{
                        cursor: "pointer",
                        background: "#f3f3f3",
                        display: "flex",
                        borderRadius: "20px",
                        boxShadow: "0px 0px 0px 2px #d7d7d7",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "24px",
                        height: "24px",
                      }}
                    >
                      <IoIosAdd style={{ fontSize: "20px" }} />
                      {/* Install Application */}
                    </span>
                    icon to add your favourite application to the Edge.
                  </span>
                </div>
              </div>
            )}

            {original_AllApplications.length > 0 &&
              apiLoaded &&
              GenerateTable(refactoredInstalledApp)}
          </div>
          {_AvailableApplications.length > 0 && apiLoaded && (
            <div
              className="addApplicationPannel"
              style={{
                width: "30%",
                backgroundColor: "#fff",
              }}
            >
              <span
                className="overFlowText availableAppSectionHeading"
                style={{
                  width: "70%",
                  color: "#000",
                  fontSize: "18px",
                  padding: "5px 5px",
                }}
              >
                Available Applications
              </span>
              <div
                style={{
                  overflow: "auto",
                  height: `${customHeight - 50}px`,
                }}
              >
                {/* <table style={{ width: "100%" }} > */}

                {/* <tbody> */}
                {_AvailableApplications.length == 0 && (
                  <NoDataComponent message="No application at the moment" />
                )}
                {_AvailableApplications.map((app, i) => (
                  <div
                    key={i}
                    style={{
                      borderBottom: "1px solid #f0f0f0",
                      padding: "4px",
                    }}
                  >
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "0px 4px",
                        fontSize: "15px",
                        position: "relative",
                      }}
                    >
                      <span style={{ width: "89%" }}>
                        <a
                          className="Hyper_link"
                          href={`appDetails?edgeId=${edgeId}&settingData=${JSON.stringify(
                            app
                          )}&isAppInstalled=false`}
                          style={{
                            textDecoration: "none",
                            fontSize: "15px",
                            background: "red",
                          }}
                        >
                          <span className="overFlowText">
                            {app?.displayName}
                          </span>
                        </a>
                        <span className="overFlowText">{app.version}</span>
                      </span>
                      <span
                        style={{
                          marginLeft: "auto",
                          display: "flex",
                          alignItems: "center",
                          columnGap: "20px",
                        }}
                      >
                        {/* <GrNotes
                           style={{ fontSize: "20px", marginRight: "5px" }}
                         /> */}
                        {/* <IoMdSettings style={{ fontSize: "23px" }} /> */}

                        {app?.dummy ? (
                          <span
                            style={{
                              cursor: "pointer",
                              background: "#f3f3f3",
                              display: "flex",
                              borderRadius: "20px",
                              boxShadow: "0px 0px 0px 2px #d7d7d7",
                              alignItems: "center",
                              justifyContent: "center",
                              paddingRight: "5px",
                            }}
                          >
                            <IoIosAdd style={{ fontSize: "20px" }} />
                            Install Application
                          </span>
                        ) : (
                          // <Popover
                          // zIndexPopup="0"
                          //   id={`${app.name}`.replace(/\s/g, "")}
                          //   placement="left"
                          //   content={
                          //     // ()=>checkApplicationSettings(app)

                          //     <div style={{ padding: "10px" }}>
                          //       <AppSettings settingData={app} hide={hide} />
                          //     </div>

                          //   }
                          //   trigger="click"
                          //   open={open[i]}
                          //   onOpenChange={handleOpenChange}
                          //   onClick={() => {
                          //     setSudoState((prev) => prev + 1);
                          //     setThisOpen(i);
                          //   }}
                          // >
                          <>
                            <span
                              style={{
                                cursor: "pointer",
                                background: "#f3f3f3",
                                display: "flex",
                                borderRadius: "20px",
                                boxShadow: "0px 0px 0px 2px #d7d7d7",
                                alignItems: "center",
                                justifyContent: "center",
                                // paddingRight: "5px",
                              }}
                              onClick={() => {
                                showAppSettings(`settings_${app.type}`);
                              }}
                            >
                              {showSettings &&
                              conId === `settings_${app.type}` ? (
                                <IoIosRemove style={{ fontSize: "20px" }} />
                              ) : (
                                <IoIosAdd style={{ fontSize: "20px" }} />
                              )}
                            </span>

                            <div
                              style={{ visibility: "hidden" }}
                              id={`settings_${app.type}`}
                              className="customModal"
                            >
                              {showSettings &&
                                conId === `settings_${app.type}` && (
                                  <div style={{ padding: "10px" }}>
                                    <AppSettings
                                      settingData={app}
                                      showAppSettings={showAppSettings}
                                      containerid={conId}
                                    />
                                    {/* <SpinnerLoader /> */}
                                  </div>
                                )}
                            </div>
                          </>
                          // </Popover>
                        )}
                      </span>
                    </span>
                  </div>
                ))}
                {/* </tbody> */}
                {/* </table> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AllapplicationsComponents;
