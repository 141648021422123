import { Button, Col, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import Heading from "../../components/SectionHeading/Heading";
import { General_color } from "../../constants/GeneralConstants";
import { BiChevronRight, BiRightArrow, BiRightIndent } from "react-icons/bi";
import { MdArrowRightAlt } from "react-icons/md";
import { RiArrowRightLine } from "react-icons/ri";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import Axios from "axios";
import SpinnerLoader from "../../components/SpinnerLoader/SpinnerLoader";
import WarningIcons from "../../components/WarningIcons/WarningIcons";
import { setPageNotifications } from "../../reduxStore/actions";
import { useDispatch, useSelector } from "react-redux";
import { CreateGuid, generateTime } from "../../constants/commonFunction";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";
import { TiTimes, TiTimesOutline } from "react-icons/ti";

function SoftwareVersionsComponents() {
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");
  const dispatch = useDispatch();
  const [showNewUpdate, setShowNewUpdate] = useState(false);
  const [osDetails, setOsDetails] = useState(null);
  const [isApiLoaded, setApiLoaded] = useState(false);
  const [updateBtnLoading, setUpdateBtnLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      // url: apiEndPoints.FETCH_OS(edgeId),
      url: apiEndPoints.FETCH_OS("351944b7-cc33-a0a1-a488-73e93433bc7c"),
      
    };

    Axios.request(config)
      .then((response) => {
        setOsDetails(response.data);
        setApiLoaded(true);
      })
      .catch((error) => {
        console.log("This is err", error);
        setIsError(true)
        setApiLoaded(true);
      });
  }, []);

  const renderHeading = (txt) => {
    return (
      <div
        className="Heading_container"
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: "10px",
          width: "100%",
          borderBottom: "1px solid #f0f0f0",
        }}
      >
        <Heading text={txt} />
        {!isApiLoaded && <SpinnerLoader />}
      </div>
    );
  };

  const renderButton = (txt, type, handleClick, loading) => {
    return (
      <Button
        //   htmlType="submit"
        //   id="bc"
        //   type="primary"
        //   loading={isSaving}
        icon={txt==="Close"?<TiTimes />:''}
        className="text_1"
        style={{
          background: type ? "#1f1f1f" : General_color.disable,
          color: type ? "#fff" : "#000",
          display: "flex",
          alignItems: "center",
          borderRadius: "31px",
          padding: `{${txt}==="Close"?"0":"5px 15px"}`,
          // boxShadow: " 0px 4px 6px -1px #d2d2d2",
          cursor: "pointer",
          fontWeight: "500",
          border: "0px",
          //   color: "white",
          // marginLeft: "auto",
          fontFamily: "ABBVoice",
          justifyContent:'center'
        }}
        loading={loading}
        onClick={handleClick}
        //   disabled={trueFalse()}
      >
        {txt==="Close"?"":txt}
      </Button>
    );
  };

  let oldNotifications = useSelector((state) => state.setPageNotifications);

  const AddEdgeSettingsNotification = async (notiText, notiId, data) => {
    let previosData = [];
    if (oldNotifications.newData.length > 0) {
      previosData = [...oldNotifications.newData];
    }

    previosData.push({
      notiId: `${notiId}`,
      text: `${notiText}`,
      time: data.time,
      data,
    });

    dispatch(
      setPageNotifications({
        payload: {
          isNew: true,
          data: oldNotifications.data,
          newData: previosData,
          // ...oldNotifications.newData,
        },
      })
    );
  };
  
let notiMsg;
  const handleUpdateOS = () =>{
    setUpdateBtnLoading(true)
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      // url: apiEndPoints.UPDATE_OS(edgeId,osDetails?.updatedVersion),
      url: apiEndPoints.UPDATE_OS('351944b7-cc33-a0a1-a488-73e93433bc7c',osDetails?.updatedVersion),
     
    };

    Axios.request(config)
      .then((response) => {
        console.log(response.data);
        setUpdateBtnLoading(false)
        notiMsg = "OS updated successfully";
        AddEdgeSettingsNotification(
          `${notiMsg}`,
          `OsUpdate_${CreateGuid()}`,
          {
            edgeId: edgeId,
            isEdge: 'osUpdate',
            modalKey: `OsUpdate_${CreateGuid()}`,
            edgeName: localStorage.getItem("edgeName"),
            successMessage: `${notiMsg}`,
            time: generateTime(),
            isComplete: false,
            limit: 3,
            currStateValue: 0,
            icon: "good",
          }
        );
      })
      .catch((error) => {
        console.log(error);
       
        setUpdateBtnLoading(false)

      
          notiMsg = "OS update failed, please try again";
          AddEdgeSettingsNotification(
            `${notiMsg}`,
            `OsUpdate_${CreateGuid()}`,
            {
              edgeId: edgeId,
              isEdge: 'osUpdate',
              modalKey: `OsUpdate_${CreateGuid()}`,
              edgeName: localStorage.getItem("edgeName"),
              successMessage: `${notiMsg}`,
              time: generateTime(),
              isComplete: true,
              limit: 3,
              currStateValue: 0,
              icon: "failed",
            }
          );

          message.error(notiMsg);
        
      });
  }
  const renderOperatingSys = () => {
    return (
      <>
        {renderHeading("Operating System")}
      {!isError ? (
<>
        {isApiLoaded && (
          <div
            style={{
              fontFamily: "ABBVoice",
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "10px",
            }}
          >
            <div
              style={{
                width: "32%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "5px",
                justifyContent: "center",
                borderRight: "1px solid #f0f0f0",
                height: "20vh",
              }}
            >
              <span style={{ fontSize: "18px" }}>{osDetails?.name}</span>
              <span style={{ fontSize: "15px" }}>{osDetails?.version}</span>
            </div>

            <div
              style={{
                width: "32%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
                justifyContent: "center",
                borderRight: showNewUpdate ? "1px solid #f0f0f0" : "none",
                height: "20vh",
              }}
            >
              <span style={{ fontSize: "15px", display: "flex", gap: "20px" }}>
                {osDetails?.isUpdateAvailable ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <span>
                      Updates Available:{" "}
                      <u
                        style={{ fontWeight: "100", cursor: "pointer" }}
                        onClick={() => setShowNewUpdate(true)}
                      >
                        Version {osDetails?.updatedVersion}
                      </u>
                    </span>
                    <div>{renderButton("Update", true, () => {handleUpdateOS()}, updateBtnLoading)}</div>
                  </div>
                ) : (
                  <span style={{display:'flex', alignItems:'center', gap:'10px'}}>
                    <WarningIcons type='good' />
                   You're up to date
                  </span>
                )}
              </span>
            </div>
            <div
              style={{
                width: "32%",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                overflow: "auto",
                maxHeight: "35vh",
                height: "45vh",
                alignItems:'center'
                //   paddingBottom:'10px'
              }}
            >
              {showNewUpdate && (
                <div style={{position:'relative', display:'flex', flexDirection:'column'}}>
                  <b style={{ fontSize: "15px" }}>What's New</b>
                  <span style={{ fontSize: "15px" }}>
                    Version {osDetails?.updatedVersion}
                  </span>
                  <ul>
                    <li>
                      Enhanced User Interface: Enjoy a refreshed look and feel
                      with imporoved navigation and useability
                    </li>
                    <li>
                      Integration Uith Kubernetes: Seamless deploy and manage
                      containerized application with Kubernetes integration
                    </li>
                    <li>
                      Advanced Observability Tools: Gain deeper insights into
                      application performance with new observability tools
                    </li>
                    <li>
                      Performance Enhancement: Experience faster load times and
                      improved overall performance{" "}
                    </li>
                  </ul>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    {renderButton("Close", false, () =>
                      setShowNewUpdate(false)
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        </>
      ):(
        <NoDataComponent message={'No data available for OS'} />
      )}
      </>
    );
  };

  const renderEdgeniusRuntime = () => {
    return (
      <>
        {renderHeading("Edgenius Runtime")}
        <div
          style={{
            fontFamily: "ABBVoice",
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "10px",
          }}
        >
          <div
            style={{
              width: "32%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "15px",
              justifyContent: "center",
              borderRight: "1px solid #f0f0f0",
              height: "20vh",
            }}
          >
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "5px",
                justifyContent: "center",
              }}
            >
              <span style={{ fontSize: "18px" }}>Identity and Access Management</span>
              <span style={{ fontSize: "15px" }}>0.10-rc.60</span>
            </span>
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "5px",
                justifyContent: "center",
              }}
            >
              <span style={{ fontSize: "18px" }}>AppHosting Service</span>
              <span style={{ fontSize: "15px" }}>0.10-rc.60</span>
            </span>
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "5px",
                justifyContent: "center",
              }}
            >
              <span style={{ fontSize: "18px" }}>AppHosting Service</span>
              <span style={{ fontSize: "15px" }}>0.10-rc.60</span>
            </span>
          </div>

          <div
            style={{
              width: "32%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
              justifyContent: "center",
              borderRight: false ? "1px solid #f0f0f0" : "none",
              height: "20vh",
            }}
          >
            <span style={{ fontSize: "15px" }}>Latest Runtime Available</span>
            {renderButton("Apply Updates", true, () => ({}))}
          </div>
          <div
            style={{
              width: "32%",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              overflow: "auto",
              maxHeight: "35vh",
              height: "45vh",
              //   paddingBottom:'10px'
            }}
          >
            {false && (
              <>
                <b style={{ fontSize: "15px" }}>What's New</b>
                <span style={{ fontSize: "15px" }}>Version 0.10-rc.67</span>
                <ul>
                  <li>
                    Enhanced User Interface: Enjoy a refreshed look and feel
                    with imporoved navigation and useability
                  </li>
                  <li>
                    Integration Uith Kubernetes: Seamless deploy and manage
                    containerized application with Kubernetes integration
                  </li>
                  <li>
                    Advanced Observability Tools: Gain deeper insights into
                    application performance with new observability tools
                  </li>
                  <li>
                    Performance Enhancement: Experience faster load times and
                    improved overall performance{" "}
                  </li>
                </ul>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  {renderButton("Close", false, () => setShowNewUpdate(false))}
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  };
  return (
    <Row style={{ gridGap: "10px", paddingRight: "20px" }}>
      <Col
        flex="1 1 525px"
        style={{
          columnGap: "10px",
          display: "grid",
          rowGap: "10px",
        }}
      >
        <Row style={{ gridGap: "10px" }}>
          <Col
            className="gridContainer"
            flex="1"
            style={{
              borderRadius: "10px",
              height: "45vh",
            }}
          >
            <div className="">{renderOperatingSys()}</div>
          </Col>
        </Row>
        <Row style={{ gridGap: "10px" }}>
          <Col
            className="gridContainer"
            flex="1"
            style={{
              borderRadius: "10px",
              height: "45vh",
            }}
          >
            <div className="">{renderEdgeniusRuntime()}</div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default SoftwareVersionsComponents;
