import React from 'react'
import Sidebar from '../components/SideBar/Sidebar'
import HierarchyPageComponent from './pageComponents/HierarchyPageComponent'

const HierarchyPage = () => {
  return (
    <section className="row-flex">
    <div className="sidebarContainer">
      <Sidebar />
    </div>
    <div className="MainContainer">
        <HierarchyPageComponent />
    </div>
  </section>
  )
}

export default HierarchyPage