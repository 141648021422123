import { Col, Row, Table, message } from "antd";
import React, { useState } from "react";
import { Upload, Button, Progress, Divider } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { General_color } from "../../constants/GeneralConstants";
import Heading from "../../components/SectionHeading/Heading";
import { IoAddSharp } from "react-icons/io5";
import { Download } from "grommet-icons";
import WarningIcons from "../../components/WarningIcons/WarningIcons";
import { RiDeleteBin4Line } from "react-icons/ri";
import {
  CreateGuid,
  generateTime,
  renderCalculatedTime,
} from "../../constants/commonFunction";
import SpinnerLoader from "../../components/SpinnerLoader/SpinnerLoader";
import { useDispatch, useSelector } from "react-redux";
import { setPageNotifications } from "../../reduxStore/actions";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";
import axios from "axios";
import { apiEndPoints } from "../../constants/ApiEndPoints";

const { Dragger } = Upload;

const HierarchyPageComponent = () => {
  const [fileContent, setFileContent] = useState(false);
  const [fileName, setFileName] = useState("");
  const [progressVal, setProgressVal] = useState(0);
  const dispatch = useDispatch();
  const [controlPlaneData, setControlPlaneData] = useState([
    {
      name: "ControlPlane_Alpha_1",
      status: "good",
      timeStamp: "1717566215255",
      Download: "",
      id: "asdf1",
    },
    {
      name: "ControlPlane_Alpha_2",
      status: "good",
      timeStamp: "1717566233353",
      Download: "",
      id: "asdf2",
    },
    // { name: "ControlPlane.Alpha 3", status: "INFO", Download: "" },
  ]);
  const options = ["General", "Management Plane", "Settings"];
  const [sudoState, setSudoState] = useState(0);
  const [selectedSystem, setSlectedSystem] = useState(options[1]);
  const [isImportStarted, setIsImportStarted] = useState(false);
  const [importedPercent, setimportedPercent] = useState(0);

  const handleFileChange = (info) => {
    // if (info.file.status === 'done') {
    let count = 1;
    setProgressVal(0);
    setTimeout(() => {
      const interval = setInterval(() => {
        console.log(count);
        count++;
        setProgressVal(count++);
        if (count > 100) {
          clearInterval(interval);
          readFileContent(info.file.originFileObj);
        }
      }, 2);
    }, 1000);

    setFileName(info.file.name);
    // }
  };

  const readFileContent = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      let jsonConvertedData = JSON.parse(event.target.result);
      setFileContent(jsonConvertedData.Applications);
      console.log(jsonConvertedData.Applications);
    };
    reader.readAsText(file);
  };

  const PreviewColumns = [
    {
      title: "Application Name",
      dataIndex: "Name",
      key: "name",
      width: "40%",
    },
    {
      title: "Version",
      dataIndex: "Version",
      key: "status",
      width: "40%",
      render: (version) => <span>{version}</span>,
    },
  ];

  const GeneratePreviweTable = (previewData) => {
    return (
      <Table
        columns={PreviewColumns}
        dataSource={previewData}
        pagination={false}
        scroll={{
          y: "55vh",
        }}
        size="small"
        style={{
          width: "100%",
          flex: "1",
        }}
      />
    );
  };

  let notiMsg;
  let oldNotifications = useSelector((state) => state.setPageNotifications);

  const AddEdgeSettingsNotification = (notiText, notiId, data) => {
    let previosData = [];

    if (oldNotifications.newData.length > 0) {
      previosData = [...oldNotifications.newData];
    }

    previosData.push({
      notiId: `${notiId}`,
      text: `${notiText}`,
      time: data.time,
      data,
    });
    console.log("clicked", previosData);

    dispatch(
      setPageNotifications({
        payload: {
          isNew: true,
          data: oldNotifications.data,
          newData: previosData,
          // ...oldNotifications.newData,
        },
      })
    );
  };

  const handleImportFile = () => {
    setIsImportStarted(true);
    let count = 0;
    let percentValue = 0;
    setimportedPercent(0);
    setTimeout(() => {
      const Percentinterval = setInterval(() => {
        console.log(count);
        count = count + 5;
        percentValue = (count / 120) * 100;

        setimportedPercent(percentValue);
        if (count > 120) {
          clearInterval(Percentinterval);
          setIsImportStarted(false);

          notiMsg = "Package imported successfully";
          message.success(notiMsg);
          AddEdgeSettingsNotification(
            `${notiMsg}`,
            `AppRelatedUpdate_${CreateGuid()}`,

            {
              edgeId: "asdf-asdf-213-asdf",
              isEdge: false,
              modalKey: `AppRelatedUpdate_${CreateGuid()}`,
              edgeName: localStorage.getItem("edgeName"),
              appName: "Package import",
              successMessage: `${notiMsg}`,
              time: generateTime(),
              isComplete: true,
              limit: 3,
              currStateValue: 0,
              icon: "good",
            }
          );
        }
      }, 2);
    }, 1000);
  };
  const renderSyncSettings = () => {
    return (
      <>
        <div style={{ display: "flex", gap: "20px" }}>
          <div>
            <Dragger
              name="file"
              multiple={false}
              showUploadList={false}
              onChange={handleFileChange}
              className="uploader"
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to import</p>
            </Dragger>

            {fileContent && (
              <div style={{ marginTop: "20px" }}>
                <span style={{ fontFamily: "ABBVoice", fontSize: "15px" }}>
                  {fileName}
                </span>
                <Progress percent={progressVal} status="" />
              </div>
            )}
          </div>

          {fileContent && (
            <div
              style={{
                padding: "10px",
                border: "1px solid #ccc",
                width: "70%",
                borderRadius: "10px",
                height: "70vh",
              }}
            >
              <div style={{ display: "flex", gap: "10px" }}>
                <span style={{ fontFamily: "ABBVoice", fontSize: "15px" }}>
                  Package Preview
                </span>
                {isImportStarted && (
                  <>
                    <span
                      style={{
                        fontFamily: "ABBVoice",
                        fontSize: "15px",
                        marginLeft: "auto",
                      }}
                    >
                      {importedPercent < 100
                        ? "Importing"
                        : "Successfully imported"}
                    </span>

                    <Progress
                      percent={importedPercent.toFixed(1)}
                      status=""
                      style={{ width: "30%" }}
                    />
                  </>
                )}
              </div>
              <hr />
              {GeneratePreviweTable(fileContent)}
            </div>
          )}
        </div>

        <div
          className="button-group"
          style={{
            display: "flex",
            columnGap: "20px",
            marginTop: "20px",
            paddingRight: "20px",
          }}
        >
          <Button
            htmlType="submit"
            id="bc"
            type="primary"
            loading={isImportStarted}
            // icon={<PoweroffOutlined />}
            className="text_1"
            style={{
              background: !fileContent ? General_color.disable : "#1f1f1f",
              display: "flex",
              alignItems: "center",
              borderRadius: "31px",
              padding: "5px 15px",
              // boxShadow: " 0px 4px 6px -1px #d2d2d2",
              cursor: !fileContent ? "not-allowed" : "pointer",
              fontWeight: "500",
              border: "0px",
              color: "white",
              marginLeft: "auto",
              fontFamily: "ABBVoice",
            }}
            onClick={handleImportFile}
            disabled={!fileContent}
          >
            Import
          </Button>
          <Button
            // sizeClass="medium"
            className="text_1"
            style={{
              fontFamily: "ABBVoice",
              color: !fileContent ? General_color.disable : "#000",
              fontWeight: "500",
              // background:'#000',
              padding: "0px 15px",
              borderRadius: "100px",
            }}
            variant="outline"
            onClick={() => setFileContent(false)}
            disabled={!fileContent ? true : false}
          >
            Reset
          </Button>
        </div>
      </>
    );
  };

  const changeStatus = (statusID, index, record) => {
    let thistime = generateTime();
    //  let status = document.getElementById(`${statusID}_status`)
    //  let prevStatus = status.innerHTML;
    //   status.innerHTML = 'processing'
    let tempData = [...controlPlaneData];
    tempData[index].status = "spinner";
    // tempData[index].timeStamp = thistime;
    setControlPlaneData(tempData);

    let time = document.getElementById(`${statusID}_time`);
    let link = document.getElementById(`${statusID}_link`);
    // time.innerHTML= 'Started now'
    time.innerHTML = "";

    let timePasting = setInterval(() => {
      //
    }, 1000);

    let data = JSON.stringify({
      packageName: record.name,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: apiEndPoints.CREATE_PACKAGE,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    setTimeout(() => {
      axios
        .request(config)
        .then((response) => {
          console.log(response.data);

          // status.innerHTML=prevStatus
          let tempData = [...controlPlaneData];
          tempData[index].status = "good";
          let thistime = generateTime();
          tempData[index].timeStamp = thistime;
          tempData[index].Download = response.data.downloadURL;
          console.log(tempData);
          link.setAttribute("href", response.data.downloadURL);
          setControlPlaneData(tempData);
          // time.innerHTML = renderCalculatedTime(thistime);
          setSudoState((prev) => prev + 1);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 2000);
  };

  const columns = [
    {
      title: "Control Plane",
      dataIndex: "name",
      width: "30%",
      ellipsis: true,
      render: (text, record, index) => {
        // if(record?.children?.length>0){
        //   return <a onClick={()=>{
        //     const href=`/appDetails?edgeId=${edgeId}&settingData=${JSON.stringify(record.originalObject)}&isAppInstalled=true`

        //       window.location.href = `${href}`
        //   }}>{text}</a>
        // }else{
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "20px",
              paddingRight: "10px",
            }}
          >
            <span
              style={{
                color: record.status == "INFO" ? General_color.disable : "",
              }}
            >
              {text}
            </span>
            {record.status == "INFO" ? (
              ""
            ) : (
              <a
                className="Hyper_link"
                style={{ cursor: "pointer" }}
                disabled={record.status == "spinner" ? true : false}
                onClick={() =>
                  changeStatus(`${index}_${record.id}`, index, record)
                }
              >
                Create artifact package
              </a>
            )}
          </span>
        );
        // }
      },
      sorter: (a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      },
      // responsive: ['md'],
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "20%",
      ellipsis: true,
      render: (status, record, index) =>
        status != null ? (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "15px",
              paddingRight: "10px",
            }}
          >
            {" "}
            <span
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                color: record.status == "INFO" ? General_color.disable : "",
              }}
              id={`${index}_${record.id}_status`}
            >
              {status == "spinner" ? (
                <SpinnerLoader />
              ) : (
                <WarningIcons
                  type={status}
                  style={{ color: "rgb(255 162 0 / 25%) !important" }}
                />
              )}
              {status == "good" && "Ready"}
              {status == "failed" && "Failed"}
              {status == "INFO" && "Pending"}
              {status == "spinner" && "Preparing"}
            </span>{" "}
            <span id={`${index}_${record.id}_time`}>
              {record.status == "INFO"
                ? ""
                : renderCalculatedTime(record.timeStamp)}
            </span>
          </span>
        ) : (
          "-"
        ),
      // responsive: ['md'],
    },
    {
      title: "Download",
      dataIndex: "download",
      // ellipsis: true,
      width: "20%",
      render: (download, record, index) => (
        <>
          {record.status == "INFO" ? (
            ""
          ) : (
            <a
              className="Hyper_link"
              id={`${index}_${record.id}_link`}
              href={`${download}`}
              target={download === undefined ? `` : `_blank`}
              disabled={record.status == "spinner" ? true : false}
            >
              Link
            </a>
          )}
        </>
      ),
      // responsive: ['md'],
    },
    {
      title: "Manage",
      dataIndex: "manage",
      width: "20%",
      ellipsis: true,
      render: (manage, record) => (
        <a
          className="Hyper_link"
          disabled={record.status == "spinner" ? true : false}
        >
          {" "}
          {record.status == "INFO" ? "" : <RiDeleteBin4Line />}
        </a>
      ),
      // responsive: ['md'],
    },
  ];

  const GenerateTable = (controlPlaneData) => {
    return (
      <Table
        columns={columns}
        dataSource={controlPlaneData}
        pagination={false}
        // scroll={{
        //   y: customHeight - 45,
        // }}
        size="small"
        // expandable={{
        //   expandIcon: ({ expanded, onExpand, record }) => {
        //     if (record?.children?.length > 0) {
        //       return expanded ? (
        //         <BiChevronDown
        //           style={{
        //             fontSize: "20px",
        //             marginBottom: "-5px",
        //             width: "30px",
        //             marginLeft: "-10px",
        //           }}
        //           onClick={(e) => {
        //             onExpand(record, e);
        //             e.stopPropagation();
        //           }}
        //         />
        //       ) : (
        //         <BiChevronRight
        //           style={{
        //             fontSize: "20px",
        //             marginBottom: "-5px",
        //             width: "30px",
        //             marginLeft: "-10px",
        //           }}
        //           onClick={(e) => {
        //             onExpand(record, e);
        //             e.stopPropagation();
        //           }}
        //         />
        //       );
        //     } else {
        //       return (
        //         <BiChevronRight
        //           style={{
        //             fontSize: "0px",
        //             marginBottom: "-5px",
        //             width: "30px",
        //             marginLeft: "-10px",
        //           }}
        //           onClick={(e) => {
        //             onExpand(record, e);
        //             e.stopPropagation();
        //           }}
        //         />
        //       );
        //     }
        //   },
        // }}
        style={{
          width: "100%",
          flex: "1",
        }}
      />
    );
  };

  const renderControlPlaneSettings = () => {
    return (
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <span className="heading_2">Distributed Control Plane</span>
          <Button
            htmlType="submit"
            id="bc"
            type="primary"
            loading={false}
            icon={<IoAddSharp />}
            className="text_1"
            style={{
              background: false ? General_color.disable : "#1f1f1f",
              display: "flex",
              alignItems: "center",
              borderRadius: "31px",
              padding: "5px 15px",
              // boxShadow: " 0px 4px 6px -1px #d2d2d2",
              cursor: false ? "not-allowed" : "pointer",
              fontWeight: "500",
              border: "0px",
              color: "white",
              marginLeft: "auto",
              fontFamily: "ABBVoice",
            }}
            // onClick={handleSave}
            disabled={false}
          >
            Management Plane
          </Button>
        </div>
        {GenerateTable(controlPlaneData)}
      </div>
    );
  };
  return (
    <Row style={{ gridGap: "10px", paddingRight: "20px" }}>
      <Col flex="0 1 100%" sm={24} md={24} lg={24} xl={6}>
        <Row>
          <Col
            className="gridContainer"
            style={{ borderRadius: "10px", height: "92vh", width: "92vw" }}
          >
            <div
              className="Heading_container"
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "10px",
                width: "98%",
                borderBottom: "1px solid #f0f0f0",
              }}
            >
              <Heading text="Settings" />{" "}
              {/* {!isApiLoaded && <SpinnerLoader />} */}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10px",
                // marginTop:'50px'
              }}
            >
              {options.map((item, index) => {
                return (
                  <>
                    <span
                      onClick={() => setSlectedSystem(item)}
                      style={{
                        background:
                          selectedSystem === item ? "#D1ebff" : "#e2e2e2",
                        padding: "10px",
                        marginTop: "10px",
                        marginBottom: "10px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        fontFamily: "ABBVoice",
                      }}
                    >
                      {item}
                    </span>
                  </>
                );
              })}
            </div>
          </Col>
        </Row>
      </Col>
      <Col
        flex="1 1 525px"
        className="gridContainer"
        style={{
          columnGap: "10px",
          display: "grid",
          rowGap: "10px",
        }}
      >
        <div>
          <div
            className="Heading_container"
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "10px",
              width: "98%",
              borderBottom: "1px solid #f0f0f0",
            }}
          >
            <Heading text="Export Artifacts" />{" "}
            {/* {!isApiLoaded && <SpinnerLoader />} */}
          </div>
          <div
            style={{
              paddingRight: "10px",
              paddingLeft: "20px",
              marginTop: "20px",
              height: "75vh",
            }}
          >
            {/* {selectedSystem === options[1] && renderSyncSettings()} */}
            {selectedSystem === options[0] && <NoDataComponent />}
            {selectedSystem === options[1] && renderControlPlaneSettings()}
            {selectedSystem === options[2] && <NoDataComponent />}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default HierarchyPageComponent;
