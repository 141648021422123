import React, { useEffect, useState } from "react";
import Heading from "../../components/SectionHeading/Heading";
import { GrNotes } from "react-icons/gr";
import { IoMdSettings, IoIosAdd } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import WarningIcons from "../../components/WarningIcons/WarningIcons";
import { routes } from "../../constants/RoutesConstants";
import { useDispatch, useSelector } from "react-redux";
import SpinnerLoader from "../../components/SpinnerLoader/SpinnerLoader";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import Axios from "axios";
import { setEdgesMetrics, createEdge } from "../../reduxStore/actions";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";
import { Modal, Form, Input, Button, message, Table, Tooltip } from "antd";
import { useHistory } from "react-router";
import { RiDeleteBin6Fill } from "react-icons/ri";

function AlledgesComponents() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [view, setView] = useState(true);
  const [showAddApplicationPannel, setShowAddApplicationPannel] =
    useState(false);
  const AllEdges = useSelector((state) => state.setEdgesMetrics.data);
  const isLoading = useSelector((state) => state.setEdgesMetrics.isLoading);
  const [reFactoredEdges, setRefactoredEdges] = useState([]);
  const apiReloader = useSelector((state) => state.setApiReloader);

  const isSummaryVisible = useSelector((state) => state.setSummaryVisible);
  const [customHeight, setCustomHeight] = useState(0);
  const [sudoUpdate, setSudoUpdate] = useState(0);

  const { TextArea } = Input;
  const [formRef] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [edgeToDelete, setEdgeToDelete] = useState(null);
  const [confirmationText, setConfirmationText] = useState("");
  const [isDeleteButtonEnabled, setIsDeleteButtonEnabled] = useState(false);
  const [isLoader, setIsloader] = useState(false);
  const [delEdgeLoad, setDeledgeLod] = useState(false);
  const showDeleteModal = (edgeId, edgeName) => {
    setEdgeToDelete({ edgeId: edgeId, name: edgeName });
    setConfirmationText("");
    setIsDeleteButtonEnabled(false);
    setDeleteModalVisible(true);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleDeleteOk = () => {
    handleDeleteEdge(edgeToDelete);
    setDeleteModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
  };

  const handleCreateEdge = async (values) => {
    setIsloader(true);
    let createEdge_config = {
      method: "post",
      maxBodyLength: Infinity,
      url: apiEndPoints.CREATE_EDGE,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(values),
    };
    try {
      const response = await Axios.post(
        apiEndPoints.CREATE_EDGE,
        values,
        createEdge_config
      );
      dispatch(createEdge({ payload: response.data }));
      const newEdge = {
        applicationSummary: null,
        applications: [],
        connectivityStatus: "Not Available",
        edgeId: response.data.objectId,
        edgeMetrics: [],
        edgeNode: "1",
        edgeType: null,
        location: response.data.location,
        name: response.data.name,
        platformServices: null,
        platformType: "connectondemand",
        project: "Edgenius",
      };
      const updatedEdges = [...AllEdges, newEdge];
      dispatch(
        setEdgesMetrics({ payload: { data: updatedEdges, isLoading: false } })
      );
      setIsloader(false);
      message.success("Successfully Created an Edge");
      formRef.resetFields();
      setIsModalOpen(false);
      setSudoUpdate((prev) => prev + 1);
    } catch (error) {
      console.error(error);
      setIsloader(false);
      message.error("Creation of new edge failed");
      setIsModalOpen(false);
    }
  };

  const handleEdgeNameChange = (value) => {
    const edgeExists = AllEdges.some((edge) => edge.name === value);
    if (edgeExists) {
      message.warning("Edge name already exists");
    }
  };

  const handleDeleteEdge = async () => {
    if (confirmationText.toLowerCase() === "yes") {
      setDeledgeLod(true);
      let deleteEdge_config = {
        method: "post",
        maxBodyLength: Infinity,
        url: apiEndPoints.DELETE_EDGE,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          id: edgeToDelete.edgeId,
          name: edgeToDelete.name,
        }),
      };
      try {
        const response = await Axios.post(
          apiEndPoints.DELETE_EDGE,
          {
            id: edgeToDelete.edgeId,
            name: edgeToDelete.name,
          },
          deleteEdge_config
        );
        if (response.data.isDeletedSuccessfully) {
          const updatedEdges = AllEdges.filter(
            (edge) => edge.edgeId !== edgeToDelete.edgeId
          );
          dispatch(
            setEdgesMetrics({
              payload: { data: updatedEdges, isLoading: false },
            })
          );
          setDeledgeLod(false);
          message.success("Edge deleted successfully");
        } else {
          setDeledgeLod(false);
          message.error(
            response.data.error || "Failed to delete edge. Please try again."
          );
        }
      } catch (error) {
        setDeledgeLod(false);
        console.error(error);
        message.error("An error occurred while deleting the edge");
      } finally {
        setDeledgeLod(false);
        setEdgeToDelete(null);
        setDeleteModalVisible(false);
        setConfirmationText("");
        setIsDeleteButtonEnabled(false); // Disable the button after successful deletion
      }
    } else {
      message.error("Please type 'yes' to confirm deletion.");
    }
  };

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: apiEndPoints.FETCH_ALL_EDGED_APP_METRICS,
      headers: {
        // Authorization: `Bearer ${access_token}`,
      },
    };

    Axios.request(config)
      .then((response) => {
        dispatch(
          setEdgesMetrics({
            payload: { data: response.data.edgeDetails, isLoading: false },
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [apiReloader]);

  function compare(a, b) {
    if (a.last_nom < b.last_nom) {
      return -1;
    }
    if (a.last_nom > b.last_nom) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    if (AllEdges?.length == 0) {
      return;
    }
    let collectEdges = [];

    AllEdges?.map((edge, key) => {
      collectEdges.push({
        key: edge.edgeId,
        name: edge.name,
        platformType: edge.platformType,
        location: edge.location,
        cpu: edge?.edgeMetrics?.[0]?.data?.[0]?.v,
        memory: edge?.edgeMetrics?.[1]?.data?.[0]?.v,
      });
    });

    collectEdges.sort(function (a, b) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    // console.log(collectEdges)

    setRefactoredEdges(collectEdges);
  }, [AllEdges, apiReloader]);
  const adjustHeight = () => {
    let a = 0;
    let getMainContainer = document.getElementsByClassName("gridContainer");
    let getTheAboveApp = getMainContainer[1].getElementsByClassName("App");
    let appHeight = getTheAboveApp[0].clientHeight;
    if (isSummaryVisible == true) {
      a = 0;
    } else {
      a = 23;
    }
    let finalHeight = getMainContainer[1].clientHeight - appHeight - 100 + a;
    // console.log("value of inner height",finalHeight, appHeight)
    setCustomHeight(finalHeight);
  };

  let rtime;
  let timeout = false;
  let delta = 100;

  window.addEventListener("resize", (event) => {
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;
      adjustHeight();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      resizeend();
    }, 200);
  }, [isSummaryVisible]);

  // const original_AllEdges = [
  //   {
  //     name: "Application asfdh",
  //     type:'asff12312h',
  //     connectionStatus:'connected',
  //     version: "0.1.2.4",
  //     edgeNode:'4',
  //     project:'Test Project',
  //     cpu: [{ icon: "spiral", value: "2" }],
  //     memory: [{ icon: "spiral", value: "35" }],
  //     storage: [{ icon: "spiral", value: "76" }]
  //   },
  //   {
  //     name: "Application asfdh",
  //     type:'asff12312h',
  //     connectionStatus:'connected',
  //     version: "0.1.2.4",
  //     edgeNode:'4',
  //     project:'Test Project',
  //     cpu: [{ icon: "spiral", value: "2" }],
  //     memory: [{ icon: "spiral", value: "35" }],
  //     storage: [{ icon: "spiral", value: "76" }]
  //   },

  // ];

  // const [AllEdges, setAllApplications] = useState(
  //   original_AllEdges
  // );

  const AllApplicationsToAdd = [
    "Asset Hub",
    "Abb warmStorage",
    "Cloud data publisher",
    "FTP connect",
    "Asset Hub",
    "Abb warmStorage",
    "Cloud data publisher",
    "FTP connect",
  ];

  const tableStat = (stat) => {
    return stat.map((d, i) => (
      <div
        key={i}
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "15px",
          alignItems: "center",
        }}
      >
        <span style={{ fontWeight: "500", minWidth: "45px" }}>{d.value}%</span>
        <WarningIcons type={d.icon} value={d.value} />
      </div>
    ));
  };

  const columns = [
    {
      title: "Edge Name",
      dataIndex: "name",
      width: "20%",
      ellipsis: true,
      // render: (name) => (
      //   <Tooltip placement="topLeft" title={name}>
      //     {name}
      //   </Tooltip>
      // ),
      render: (text) => <a className="edge-name">{text}</a>,
      sorter: (a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      },
      // responsive: ['md'],
    },
    {
      title: "Connectivity Mode",
      dataIndex: "platformType",
      width: "20%",
      ellipsis: true,
      render: (platformType) =>
        platformType == "unknown" ? "-" : <span>Connect on demand</span>,
      // responsive: ['md'],
    },
    {
      title: "Location",
      dataIndex: "location",
      ellipsis: true,
      width: "20%",
      render: (location) => (location != null ? <span> {location}</span> : "-"),
      // responsive: ['md'],
    },
    {
      title: "CPU",
      dataIndex: "cpu",
      ellipsis: true,
      render: (cpu) =>
        cpu != undefined
          ? tableStat([
              {
                icon: "spiral",
                value: Math.round(cpu),
              },
            ])
          : "-",
      // responsive: ['md'],
      width: "20%",
    },
    {
      title: "Memory",
      dataIndex: "memory",
      ellipsis: true,
      render: (memory) =>
        memory != undefined
          ? tableStat([
              {
                icon: "spiral",
                value: Math.round(memory),
              },
            ])
          : "-",
      width: "20%",
      // responsive: ['md'],
    },
    {
      title: "Actions",
      dataIndex: "actions",
      width: "10%",
      render: (_, record) => (
        <Tooltip title="Delete Edge">
          <RiDeleteBin6Fill
            style={{ color: "#525252", cursor: "pointer", hover: "" }}
            onClick={() => showDeleteModal(record.key, record.name)}
          />
        </Tooltip>
      ),
    },
  ];

  const GenerateTable = (applications) => {
    // console.log("refactroresEdge", applications)
    return (
      <Table
        columns={columns}
        dataSource={applications}
        pagination={false}
        scroll={{
          y: customHeight - 45,
        }}
        size="small"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (event.target.classList.contains("edge-name")) {
                const href = `${routes.EDGEDETAILS}?edgeName=${record.name}&edgeId=${record.key}&location=${record.location}`;
                window.location.href = `${href}`;
              }
            },
          };
        }}
        rowHoverBg="#c11313"
      />
    );
  };

  return (
    <div className="App">
      {/* {AllEdges.length == 0 ? (
        "sOEMYHING"
      ) : (
        <> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "30px",
        }}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "10px",
          }}
        >
          <Heading text="Edge Devices" />
          {isLoading && <SpinnerLoader />}
        </span>
        <span
          style={{
            marginLeft: "auto",
            background: "#1f1f1f",
            display: "flex",
            alignItems: "center",
            borderRadius: "30px",
            padding: "2px 10px",
            cursor: "pointer",
            color: "white",
          }}
          onClick={showModal}
        >
          <IoIosAdd style={{ fontSize: "30px" }} /> Add Edge
        </span>
        {/* This is the below code used for from/popup/modal for create edge */}
        <Modal
          style={{
            fontFamily: "ABBVoice Medium",
          }}
          title="Add New Edge"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            form={formRef}
            onFinish={handleCreateEdge}
            layout="vertical"
            initialValues={{ remember: true }}
          >
            <Form.Item
              style={{ fontFamily: "ABBVoice" }}
              label="Name"
              name="name"
              onChange={(e) => handleEdgeNameChange(e.target.value)}
              rules={[{ required: true, message: "Please enter the name" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Location"
              name="location"
              rules={[{ required: true, message: "Please enter the location" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Description" name="description">
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item>
              <div
                className="button-group"
                style={{
                  display: "flex",
                  // columnGap: "10px",
                  alignItems: "baseline",
                  marginTop: "20px",
                }}
              >
                <Button
                  loading={isLoader}
                  style={{
                    marginLeft: "auto",
                    background: "#1f1f1f",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "30px",
                    padding: "2px 10px",
                    cursor: "pointer",
                    color: "white",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Create
                </Button>
                <Button
                  style={{
                    borderRadius: "100px",
                    marginTop: "20px",
                    marginLeft: "20px",
                    fontWeight: "500",
                  }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          style={{ fontFamily: "ABBVoice Medium" }}
          title="Delete Edge"
          open={deleteModalVisible}
          onOk={handleDeleteOk}
          onCancel={handleDeleteCancel}
        >
          <p style={{ fontSize: "medium", fontFamily: "ABBVoice" }}>
            This is an irreversible action. Are you sure you want to delete the
            edge?
          </p>
          <Form onFinish={handleDeleteEdge} layout="vertical">
            <Form.Item
              label="Type 'yes' to confirm:"
              name="confirmation"
              rules={[
                { required: true, message: "Please type 'yes' to confirm." },
                {
                  validator: (_, value) => {
                    if (value.toLowerCase() === "yes") {
                      setIsDeleteButtonEnabled(true);
                      return Promise.resolve();
                    }
                    setIsDeleteButtonEnabled(false);
                    return Promise.reject("Please type 'yes' to confirm.");
                  },
                },
              ]}
            >
              <Input
                autocomplete="off"
                onChange={(e) => setConfirmationText(e.target.value)}
              />
            </Form.Item>
            <Button
              loading={delEdgeLoad}
              style={{
                marginLeft: "55%",
                background: isDeleteButtonEnabled ? "#1f1f1f" : "#848484",
                // display: "flex",
                alignItems: "center",
                borderRadius: "30px",
                // padding: "2px 10px",
                cursor: "pointer",
                fontFamily: "ABBVoice Regular",
                color: "white",
              }}
              type="primary"
              onClick={handleDeleteEdge}
              disabled={!isDeleteButtonEnabled}
            >
              Delete
            </Button>
            <Button
              style={{
                borderRadius: "30px",
                marginTop: "20px",
                marginLeft: "20px",
                fontFamily: "ABBVoice Regular",
                fontWeight: "500",
              }}
              onClick={handleDeleteCancel}
            >
              Cancel
            </Button>
          </Form>
        </Modal>
        {/* <button
          onClick={() => setShowAddApplicationPannel(!showAddApplicationPannel)}
          style={{
            marginLeft: "auto",
            background: "#d1ebff",
            display: "flex",
            alignItems: "center",
            borderRadius: "31px",
            padding: "5px 10px",
            boxShadow: " 0px 4px 6px -1px #d2d2d2",
            cursor: "pointer",
            border: 0,
          }}
          disabled
        >
          <IoIosAdd style={{ fontSize: "24px" }} /> New Edge
        </button> */}
      </div>
      <div id="tableBox">
        {AllEdges?.length > 0 && !isLoading && (
          <div
            className=""
            // style={{ display: "flex", flexDirection: "row", columnGap: "5px", height:`${ isSummaryVisible?'calc(52vh - 15vh)':'calc(80vh - 22vh)'}` }}
            style={{
              height: `${customHeight}px`,

              overflow: "hidden",
            }}
          >
            <div style={{ display: "contents", backgroundColor: "#fff" }}>
              {/* {view
              ? GenerateTable(AllEdges)
              : GenerateTable(EdgeApplications)} */}
              {AllEdges?.length > 0 &&
                !isLoading &&
                GenerateTable(reFactoredEdges)}
            </div>
            <div
              className="addApplicationPannel"
              style={{
                width: showAddApplicationPannel ? "30%" : "0px",
                backgroundColor: "#fff",
              }}
            >
              <table style={{ width: "100%" }}>
                <tr className="tableHeading">
                  <td style={{ display: "flex", alignItems: "center" }}>
                    <span className="overFlowText" style={{ width: "90%" }}>
                      List of all Edges
                    </span>
                    <span
                      onClick={() =>
                        setShowAddApplicationPannel(!showAddApplicationPannel)
                      }
                      style={{
                        backgroundColor: "#000",
                        marginLeft: "auto",
                        borderRadius: "100px",
                        padding: "2px 2px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <TiTick style={{ fontSize: "18px", color: "#fff" }} />
                    </span>
                  </td>
                </tr>
                <tr>
                  {/* <td>
                  <input
                    type="text"
                    placeholder="Filter"

                    // onChange={(e) => handleChange(e.target.value, app_column)}
                  />
                </td> */}
                </tr>

                {AllApplicationsToAdd.map((content, i) => (
                  <tr key={i}>
                    <td>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <span className="overFlowText">{content}</span>
                        <span
                          style={{
                            marginLeft: "auto",
                            display: "flex",
                            alignItems: "center",
                            columnGap: "20px",
                          }}
                        >
                          <GrNotes
                            style={{ fontSize: "20px", marginRight: "5px" }}
                          />{" "}
                          <IoMdSettings style={{ fontSize: "23px" }} />{" "}
                          <IoIosAdd style={{ fontSize: "24px" }} />
                        </span>
                      </span>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        )}

        {(AllEdges?.length == 0 || AllEdges?.length == undefined) &&
          !isLoading && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "10%",
                fontFamily: "ABBVoice-medium",
                color: "#1f1f1f",
                fontSize: "20px",
              }}
            >
              {" "}
              Welcome to Edgenius Management Console! Please Onboard your edge
              using ability platform services.
            </div>
          )}
      </div>
      {/* )} */}
    </div>
  );
}

export default AlledgesComponents;
